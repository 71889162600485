import React from 'react';
import {css} from '@emotion/core';
import {Link} from 'gatsby';

import logo from '../assets/logo.svg';
import logoBg from '../assets/logoBg.svg';
import ActionLink from '../components/action-link';
import HomeAnimation from '../components/home-animation';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu_class: ''
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.setToggleTopMenuClass = this.setToggleTopMenuClass.bind(this);
    this.dismissToggleTopMenuClass = this.dismissToggleTopMenuClass.bind(this);
  }

  setToggleTopMenuClass() {
    if (this.state.menu_class === '') {
        this.setState({
            menu_class: 'toggled',
        });
    } else {
        this.setState({
            menu_class: '',
        });
    }
  }

  dismissToggleTopMenuClass() {
    this.setState({
      menu_class: '',
    });
  }

  handleScroll() {
    const scrollingUp = this.state.oldScroll > window.scrollY;

    this.setState({scroll: window.scrollY, oldScroll: window.scrollY, scrollingUp});
  }

  componentDidMount() {
    const el = document.getElementById('mainNav');

    this.setState({top: el.offsetTop, height: el.offsetHeight});
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    if (this.state.menu_class === '') {
      this.state.scroll > (this.state.top + this.state.height) ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const showMobileNav = `${this.state.menu_class}`;

    return (
      <header
        css={css`
          background: rgb(0,155,220);
          background: linear-gradient(45deg, rgba(0,155,220,1) 0%, rgba(30,75,163,1) 100%);
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          overflow-x: hidden;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            /* IE10+ specific styles go here */  
            overflow: hidden;
          }

          svg.waves {
            width: 100%;
            display: block;
            bottom: -1px;
            height: 50px;
            fill: #fff;
    
            path:nth-of-type(1) {
              opacity: 0.15;
            }
            path:nth-of-type(2) {
              opacity: 0.3;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
              /* IE10+ specific styles go here */  
              margin-bottom: -1px;
            }
          }

          nav a {
            color: #fff;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            margin: 0 0 0 2rem;
            padding: 0.25rem;
            text-decoration: none;
            transition: all .25s ease;

            &.current-page,
            &:hover {
              color: #C6EAFF;
            }

            &:last-of-type {
              background: #fff;
              border-radius: 20px;
              padding: .25rem 1rem;
              color: #51499B;
              border: 1px solid #fff;

              @media only screen and (max-width : 740px) {
                background: transparent;
                border: 0;
                color: #fff;
              }

              &:hover {
                border: 1px solid #51499B;
                color: #fff;
                background: #51499B;

                @media only screen and (max-width : 740px) {
                  background: transparent;
                  border: 0;
                  color: #C6EAFF;
                }
              }
            }

            @media only screen and (max-width : 740px) {
              margin: 10px;
              font-size: 2rem;
              padding: 20px;
              text-align: center;
            }
          }

          #mobileMenu {
            margin: 0 20px 0 0;
            color: #fff;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1;
            margin: 0 1rem 0;
            padding: 0.25rem;
            text-decoration: none;
            transition: all .25s ease;

            &:hover {
              color: #C6EAFF;
              cursor: pointer;

              svg {
                fill: #C6EAFF;
              }
            }

            svg {
              fill: #fff;
              width: 30px;
              height: 30px;
              position: relative;
              left: 8px;
              top: 8px;
              transition: all .25s ease;
            }

            @media only screen and (min-width : 740px) {
              display: none;
            }
            @media only screen and (max-width : 740px) {
              display: inline;
              z-index: 5200;

              #icoClose {
                display: none;
              }

              &.toggled {
                position: fixed;
                right: 0;
                top: ${this.state.top}px;

                #icoMenu {
                  display: none;
                }
                #icoClose {
                  display: inline-block;
                }
              }
            }
            @media only screen and (max-width : 320px) {
              font-size: .85rem;

              svg {
                width: 20px;
                height: 20px;
                top: 5px;
              }
            }
          }

          .fixed-nav {
            position: fixed;
            top: ${-this.state.height}px;
            z-index: 2000;
            background: #fff;
            box-shadow: 0 0 7px rgba(0,0,0,.1);
            animation: move 1.5s forwards;
            max-width: 100%;
            padding: 0 calc((100vw - 1000px) / 2);

            h1 {
              padding: 10px 0 0 29px;
              width: 145px;
              height: 50px;

              @media only screen and (max-width : 740px) {
                padding: 10px 0 0 10px;
              }

              a {
                width: 80px;
                height: 29px;
              }
            }

            #mobileMenu {
              color: #51499B;
              margin: 2px 1rem 0;
              font-size: .85rem;
  
              &:hover {
                color: #C6EAFF;
                cursor: pointer;
  
                svg {
                  fill: #C6EAFF;
                }
              }

              svg {
                fill: #51499B;
                width: 20px;
                height: 20px;
                top: 5px;
              }
            }

            nav {
              margin-top: .6rem;

              a {
                color: #51499B;
                font-size: .85rem;

                &.current-page,
                &:hover {
                  color: #C6EAFF;
                }

                &:last-of-type {
                  border: 1px solid #51499B;
    
                  &.current-page {
                    color: #51499B;
                  }
                  &:hover {
                    color: #fff;
                    background: #51499B;
                  }
                }
              }
            }
          }

          @keyframes move {
            100% {
               transform: translate(0, ${this.state.height}px);
            }
          }

          @keyframes tdFadeOutUp {
            0% {
              opacity: 1;
              transform: translateY(0);
              animation-timing-function: cubic-bezier(0, 0.59, 0.375, 1);
            }
            100% {
              opacity: 0;
              transform: translateY(-10px);
            }
          }
        `}
      >
        <p className={showMobileNav} css={css`
          z-index: 5200;
          width: 100%;
          background: #fff;
          font-size: 0.75rem;
          text-align: center;
          padding-top: .25rem !important;
          padding-bottom: .25rem !important;
          margin-bottom: 0;

          @media only screen and (max-width : 1000px) {
            padding-right: .5rem;
          }

          @media only screen and (max-width : 740px) {
            &.toggled {
              position: fixed;
              top: 0;
            }
          }
        `}>
          <span css={css`
            text-align: right;
            max-width: 1000px;
            width: 100%;
            display: inline-block;
          `}>
            {/*<a css={css`
              text-decoration: none;
              color: inherit;
        `} href="tel:(03) 5611 0428">(03) 5611 0428</a>  | */}
            <a href="mailto:info@talen.com.au">info@talen.com.au</a>
          </span>
        </p>
        <section id="mainNav"
          className={this.state.scroll > (this.state.top + this.state.height) && this.state.menu_class === '' ? "fixed-nav" : ""} css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 1000px;
          width: 100%;
        `}>
          <h1 className={showMobileNav} css={css`
            margin: 0 0 0 -18px;
            padding: 10px 0 0 33px;
            width: 230px;
            height: 80px;
            background: url(${logoBg}) no-repeat transparent 0 0;
            transition: all 0.5s ease;

            @media only screen and (max-width : 1050px) {
              margin: -1px 0 0 0;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
              /* IE10+ specific styles go here */  
              margin: -1px 0 0 0;
            }

            @media only screen and (max-width : 850px) {
              width: 152px;
              height: 54px;
              padding: 7px 0 0 22px;
            }

            @media only screen and (max-width : 320px) {
              width: 115px;
              height: 40px;
              padding: 5px 0 0 16px;
            }

            @media only screen and (max-width : 740px) {
              &.toggled {
                z-index: 5100;
                position: fixed;
                top: ${this.state.top}px;
              }
            }
          `}>
            <Link to="/" onClick={this.dismissToggleTopMenuClass} css={css`
              margin: 0;
              padding: 0;
              width: 160px;
              height: 57px;
              display: inline-block;
              background: url(${logo}) no-repeat transparent;
              text-indent: -5000px;
              overflow: hidden;
              transition: all 0.5s ease;

              @media only screen and (max-width : 850px) {
                width: 106px;
                height: 38px;
              }

              @media only screen and (max-width : 320px) {
                width: 80px;
                height: 29px;
              }
            `}>
              Talen
            </Link>
          </h1>
          <nav className={showMobileNav}
            css={css`
              margin-top: 1.5rem;
              padding-right: 0;
              margin-right: 0;
              transition: all 0.5s ease;

              @media only screen and (max-width : 850px) {
                margin-top: .8rem;
              }

              @media only screen and (max-width : 740px) {
                display: none;
                flex-direction: column;
                width: 100vw;
                height: 100vh;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 5000;
                margin: 0;
                padding-top: calc(100vh/6);
                background: linear-gradient(45deg, rgba(0,155,220,1) 0%, rgba(30,75,163,1) 100%);

                &.toggled {
                  display: flex;
                  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
                }

                a {
                  height: calc(60vh/6);

                  &:last-of-type {
                    padding: 20px;
                  }
                }
              }

              @-webkit-keyframes swing-in-top-fwd {
                0% {
                  -webkit-transform: rotateX(-100deg);
                          transform: rotateX(-100deg);
                  -webkit-transform-origin: top;
                          transform-origin: top;
                  opacity: 0;
                }
                100% {
                  -webkit-transform: rotateX(0deg);
                          transform: rotateX(0deg);
                  -webkit-transform-origin: top;
                          transform-origin: top;
                  opacity: 1;
                }
              }
              @keyframes swing-in-top-fwd {
                0% {
                  -webkit-transform: rotateX(-100deg);
                          transform: rotateX(-100deg);
                  -webkit-transform-origin: top;
                          transform-origin: top;
                  opacity: 0;
                }
                100% {
                  -webkit-transform: rotateX(0deg);
                          transform: rotateX(0deg);
                  -webkit-transform-origin: top;
                          transform-origin: top;
                  opacity: 1;
                }
              }
            `}
          >
            <Link onClick={this.dismissToggleTopMenuClass} to="/services/" activeClassName="current-page" partiallyActive={true}>
              Services
            </Link>
            <Link onClick={this.dismissToggleTopMenuClass} to="/process/" activeClassName="current-page" partiallyActive={true}>
              Process
            </Link>
            <Link onClick={this.dismissToggleTopMenuClass} to="/work/" activeClassName="current-page" partiallyActive={true}>
              Work
            </Link>
            <Link onClick={this.dismissToggleTopMenuClass} to="/team/" activeClassName="current-page" partiallyActive={true}>
              Team
            </Link>
            <Link onClick={this.dismissToggleTopMenuClass} to="/contact/" activeClassName="current-page">
              Contact Us
            </Link>
          </nav>
          <span id="mobileMenu" className={showMobileNav} onClick={this.setToggleTopMenuClass}>
            Menu
            <svg id="icoMenu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M491.318 235.318H20.682C9.26 235.318 0 244.577 0 256s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 78.439H20.682C9.26 78.439 0 87.699 0 99.121c0 11.422 9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.26 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 392.197H20.682C9.26 392.197 0 401.456 0 412.879s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682s-9.259-20.682-20.682-20.682z"/>
            </svg>
            <svg id="icoClose" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </span>
        </section>
        <section className="fade-in" css={css`
          display: flex;
          justify-content: space-between;
          max-width: 1000px;
          width: 100%;
          transition: all 0.5s ease;

          @media only screen and (max-width : 1050px) {
            padding: 0 15px;
          }

          @media only screen and (max-width : 680px) {
            flex-direction: column;
          }
        `}>
          <div css={css`
            max-width: 50%;

            @media only screen and (max-width : 680px) {
              order: 2;
              width: 100%;
              max-width: 100%;
              text-align: ${this.props.pageTitle === 'Home' ? `center` : `left`};
            }
          `}>
            <h2
              className={this.props.pageTitle === "Home" ? 'home' : ''}
              css={css`
                margin-top: 50px;
                color: #fff;
                font-size: 36pt;
                margin-bottom: 1rem;

                @media only screen and (max-width : 850px) {
                  font-size: 32pt;
                  transition: all 0.5s ease;
                }

                @media only screen and (max-width : 740px) {
                  &.home {
                    font-size: 28pt;
                    margin-top: 30px;
                  }
                }
                @media only screen and (max-width : 400px) {
                  &.home {
                    font-size: 24pt;
                    margin-top: 20px;
                  }
                }
              `}>{this.props.pageTitle !== "Home" ? this.props.pageTitle : `Professional app development by Talen'ted people`}</h2>

            {this.props.pageTitle === "Home" ? <ActionLink to="/team" css={css`margin-bottom: 1rem;`}>Our Team</ActionLink> : ''}
          </div>
          { this.props.pageTitle === "Home" ? <HomeAnimation /> : ''}
        </section>
        <svg xmlns="http://www.w3.org/2000/svg" className="waves" viewBox="0 0 1000 100" preserveAspectRatio="none">
          <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
          <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
          <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>
        </svg>
      </header>
    );
  }
}

export default Header;
